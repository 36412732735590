import React, { useEffect } from "react";
import Prism from "prismjs";
import SgLayout from "~layouts/sg-layout";
import CodeBlock from '~2-components/SgCodeBlock/SgCodeBlock';
import FeatureCTA from '~2-components/FeatureCTA/FeatureCTA';
import { dummyFeatureCTAData } from '~data/dummyFeatureCTAData';
import FeatureCTAKontent from "~3-data-components/FeatureCTAKontent";

const FeatureCTAEg = `import FeatureCTA from '~2-components/FeatureCTA/FeatureCTA';

<FeatureCTA
heading='' //Required
text='' //Required
btnUrl='' //Required
btnTxt='' //Required
cmsImage = React.node //Required if coming from CMS. Usually a Gatsby Image.
imgUrl = '' //Required if NOT coming from CMS
imgAlt = '' //Required if imgUrl is set.
/>
`;

const SgFeatureCTA = () => {
    useEffect(() => {
        // call the highlightAll() function to style our code blocks
        Prism.highlightAll();
    });

    return (
        <SgLayout>
            <section className="sg-content">
                <div className="container">
                    <h1>Feature CTA</h1>
                    <h3>How to use</h3>
                    <CodeBlock
                        code={FeatureCTAEg}
                    />

                    <h3>Example</h3>
                </div>
            </section>
            <FeatureCTA {...dummyFeatureCTAData} />
            <section className="sg-content">
                <div className="container">
                    <h2>LIVE Kontent Feature CTA</h2>
                </div>
            </section>
            <FeatureCTAKontent />
        </SgLayout>
    );
};

export default SgFeatureCTA;


